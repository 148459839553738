import "./App.css";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
import Main from "@/pages";
import ToTop from "@/components/ToTop";

function App() {
  return (
    <>
      <Nav />
      <Main />
      <Footer />
      <ToTop />
    </>
  );
}

export default App;
