import { Button } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
const FreeTrial = () => {
  return (
    <div
      className="h-full mx-auto my-0"
      style={{
        background: "url(/images/home/bg2.jpg)  no-repeat",
      }}
    >
      <div className="max-w-[1280px] mx-auto my-0 py-[50px] flex justify-around items-center">
        <div className="text-center">
          <p className="text-white  text-xl md:text-xl lg:text-2xl xl:text-4xl mb-[30px]">
            云签约，让签署更简单高效！
          </p>
          <p className="max-w-[620px] text-white text-base">
            现在注册，免费试用
          </p>
        </div>
        <Link to="/yqy_down">
          <Button className="anim rounded-3xl h-[48px] w-[150px] text-black bg-gray-100 hover:bg-main-color hover:text-white border-none text-base">
            免费试用
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default FreeTrial;
