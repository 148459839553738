const Record = () => {
  return (
    <div className="py-2 text-sm text-center text-white bg-gray-900 md:text-base lg:text-lg xl:text-lg">
      Copyright © 2021 易达签章{'  '}
      <span
        className="hover:text-main-color hover:cursor-pointer"
        onClick={() =>
          window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank")
        }
      >
        浙ICP备17045174号
      </span>{'  '}
      ©杭州易达电子科技有限公司
    </div>
  );
};

export default Record;
