import { Routes, Route, Outlet } from "react-router-dom";
import routes from "@/routes/route";
import React, { Suspense } from "react";
import NotFound from "@/components/NotFound";
import ErrorPage from "@/components/ErrorPage";
import { Spin } from "antd";
const LoadingSpin = () => {
  return (
    // <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
    //   <div className="w-20 h-20 border-t-2 border-b-2 rounded-full animate-spin border-main-color"></div>
    //   Loading...
    // </div>
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <Spin size="large" />
    </div>
  );
};

function renderRoutes(routes) {
  return (
    <>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <Suspense fallback={<LoadingSpin />}>
              <Outlet />
              {!route.routes && <route.component />}
            </Suspense>
          }
          exact={route.exact}
          name={route.name}
        >
          {route.routes && renderRoutes(route.routes)}
        </Route>
      ))}
    </>
  );
}

function App() {
  return (
    <div className="min-h-screen mt-[80px]">
      <Routes>
        {renderRoutes(routes)}
        <Route path="*" element={<NotFound />} />
        <Route
          path="/error"
          element={
            <ErrorPage errorCode={500} errorMessage="Internal Server Error" />
          }
        />
      </Routes>
      <Outlet />
    </div>
  );
}

export default App;
