import React from "react";

const ErrorPage = ({ errorCode, errorMessage }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800">Error {errorCode}</h1>
      <p className="mt-4 text-gray-600">{errorMessage}</p>
    </div>
  );
};

export default ErrorPage;
