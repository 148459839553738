/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import FreeTrial from "./freeTrial";
import Record from "./record";
function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (to) => {
    navigate(to);
  };

  return (
    <>
      <FreeTrial />
      <footer
        className="py-8 bg-gray-100"
        style={{ background: "url(/images/footbg.jpg)" }}
      >
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-5">
            {/* 联系我们 */}
            <div>
              <h3 className="mb-4 text-lg font-semibold text-white">
                联系我们
              </h3>
              <ul>
                <li className="mb-2">
                  <p className="text-gray-300 hover:text-white">
                    地址：
                    <span className="text-sm">
                      浙江省杭州市萧山区金城路438号
                    </span>
                  </p>
                </li>
                <li className="mb-2">
                  <p className="text-gray-300 hover:text-white">
                    电话：
                    <span className="text-sm">18967188058</span>
                  </p>
                </li>
                <li className="mb-2">
                  <p className="text-gray-300 hover:text-white">
                    手机：
                    <span className="text-sm">18967188058</span>
                  </p>
                </li>
                <li className="mb-2">
                  <p className="text-gray-300 hover:text-white">
                    邮箱：
                    <span className="text-sm">yunqianyue@yidaplay.com</span>
                  </p>
                </li>
              </ul>
            </div>

            {/* 使用场景 */}
            <div className="hidden md:block lg:block xl:block">
              <h3 className="mb-4 text-lg font-semibold text-white">
                使用场景
              </h3>
              <ul>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() =>
                      handleNavigation("/service/digitalAuthentication")
                    }
                    className="text-gray-300 hover:text-white"
                  >
                    数字认证
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/service/signature")}
                    className="text-gray-300 hover:text-white"
                  >
                    电子签章
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/service/approval")}
                    className="text-gray-300 hover:text-white"
                  >
                    审批管理
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/service/contractManager")}
                    className="text-gray-300 hover:text-white"
                  >
                    合同管理
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/service/signet")}
                    className="text-gray-300 hover:text-white"
                  >
                    印章管理
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/service/dataDepositCertificate")}
                    className="text-gray-300 hover:text-white"
                  >
                    数据存证
                  </p>
                </li>
              </ul>
            </div>

            {/* App 下载 */}
            <div className="hidden md:block lg:block xl:block">
              <h3 className="mb-4 text-lg font-semibold text-white">
                App 下载
              </h3>
              <ul>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/yqy_down")}
                    className="text-gray-300 hover:text-white"
                  >
                    安卓下载
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/yqy_down")}
                    className="text-gray-300 hover:text-white"
                  >
                    苹果下载
                  </p>
                </li>
              </ul>
            </div>

            {/* 开放平台 */}
            <div className="hidden md:block lg:block xl:block">
              <h3 className="mb-4 text-lg font-semibold text-white">
                开放平台
              </h3>
              <ul>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/open")}
                    className="text-gray-300 hover:text-white"
                  >
                    开放接口
                  </p>
                </li>
                <li className="mb-2 hover:cursor-pointer">
                  <p
                    onClick={() => handleNavigation("/open")}
                    className="text-gray-300 hover:text-white"
                  >
                    接口文档
                  </p>
                </li>
              </ul>
            </div>

            {/* 互动平台 */}
            <div>
              <h3 className="mb-4 text-lg font-semibold text-white">
                互动平台
              </h3>
              <p className="text-white">扫描关注微信公众号</p>
              <img
                src="/images/wxQrcode.png"
                alt="微信公众号"
                className="w-40 h-40 mt-4"
              />
            </div>
          </div>
        </div>
      </footer>
      <Record />
    </>
  );
}

export default Footer;
