import { lazy } from "react";
// import NotFound from "@/components/NotFound";
// import ErrorPage from "@/components/ErrorPage";

// 异步加载组件
const Home = lazy(() => import("@/pages/home"));
const About = lazy(() => import("@/pages/about"));
const Service = lazy(() => import("@/pages/service"));
const App = lazy(() => import("@/pages/app"));
const Open = lazy(() => import("@/pages/open"));
const Join = lazy(() => import("@/pages/join"));
const VideoCourse = lazy(() => import("@/pages/videoCourse"));

const DigitalAuthentication = lazy(() =>
  import("@/pages/service/child/digitalAuthentication")
);
const Signature = lazy(() => import("@/pages/service/child/signature"));
const Approval = lazy(() => import("@/pages/service/child/approval"));
const ContractManager = lazy(() =>
  import("@/pages/service/child/contractManager")
);
const Signet = lazy(() => import("@/pages/service/child/signet"));
const DataDepositCertificate = lazy(() =>
  import("@/pages/service/child/dataDepositCertificate")
);
const AboutUs = lazy(() => import("@/pages/aboutUs"));
const Articles = lazy(() => import("@/pages/articles"));

const routes = [
  {
    path: "/",
    name: "home",
    exact: true,
    component: Home,
  },
  {
    path: "/service",
    name: "service",
    exact: true,
    routes: [
      {
        path: "/service",
        name: "service",
        exact: true,
        component: Service,
      },
      {
        path: "/service/digitalAuthentication",
        name: "digitalAuthentication",
        exact: true,
        component: DigitalAuthentication,
      },
      {
        path: "/service/signature",
        name: "signature",
        exact: true,
        component: Signature,
      },
      {
        path: "/service/approval",
        name: "approval",
        exact: true,
        component: Approval,
      },
      {
        path: "/service/contractManager",
        name: "contractManager",
        exact: true,
        component: ContractManager,
      },
      {
        path: "/service/signet",
        name: "signet",
        exact: true,
        component: Signet,
      },
      {
        path: "/service/dataDepositCertificate",
        name: "dataDepositCertificate",
        exact: true,
        component: DataDepositCertificate,
      },
    ],
  },
  {
    path: "/yqy_down",
    name: "download",
    exact: true,
    component: App,
  },
  {
    path: "/open",
    name: "App下载",
    exact: true,
    component: Open,
  },
  {
    path: "/contact-us",
    name: "contactUs",
    exact: true,
    component: About,
  },
  {
    path: "/video-tutorial",
    name: "videoTutorial",
    exact: true,
    component: VideoCourse,
  },
  {
    path: "/join",
    name: "join",
    exact: true,
    component: Join,
  },
  {
    path: "/about",
    name: "aboutUs",
    exact: true,
    component: AboutUs,
  },
  {
    path: "/articles",
    name: "articles",
    exact: true,
    component: Articles,
  },
];

export default routes;
