/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";

function Nav() {
  // 用于管理下拉菜单可见性的状态
  const [showDropdown, setShowDropdown] = useState(false);
  const [hovered, setHovered] = useState("");
  const [showMenu, setShowMenu] = useState(false); //移动端菜单显示隐藏
  const nodeRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (to) => {
    setShowMenu(false);
    navigate(to);
  };

  const isMatched = (path) => {
    return (
      (location.pathname !== "/" &&
        path !== "/" &&
        location.pathname.includes(path)) ||
      (location.pathname === "/" && location.pathname === path)
    );
  };

  useEffect(() => {
    if (showDropdown) {
      nodeRef.current.style.transition = "none";
      nodeRef.current.style.height = "auto";
      const height = nodeRef.current.offsetHeight;
      nodeRef.current.style.height = 0;
      const a = nodeRef.current.clientHeight;
      nodeRef.current.style.transition = ".5s";
      nodeRef.current.style.height = height + "px";
    } else {
      nodeRef.current.style.height = 0;
      nodeRef.current.style.transition = 0;
    }
  }, [showDropdown]);

  return (
    <>
      <div className=" bg-gray-100 flex items-center justify-between sm:justify-around md:justify-around lg:justify-around xl:justify-around px-4 py-2 min-h-[80px] fixed z-10 top-0 left-0 w-full">
        <div className="flex items-center cursor-default hover:cursor-pointer">
          <img
            src="/logo.png"
            alt="Logo"
            className="w-32 h-10 mr-2 "
            onClick={() => handleNavigation("/")}
          />
          <span
            className="hidden text-lg font-semibold text-main-color hover:text-hover-color sm:block md:hidden lg:hidden xl:block"
            onClick={() => handleNavigation("/")}
          >
            杭州易达电子科技有限公司
          </span>
        </div>
        <div
          className="z-10 md:hidden lg:hidden"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          {!showMenu && (
            <svg
              t="1711609482386"
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5133"
              width="16"
              height="16"
            >
              <path
                d="M66.488889 211.781818h891.022222c28.198788 0 50.980202-22.238384 50.980202-49.648485 0-27.397172-22.768485-49.648485-50.980202-49.648485H66.488889C38.341818 112.484848 15.508687 134.723232 15.508687 162.133333s22.833131 49.648485 50.980202 49.648485z m891.009293 248.242424H66.488889C38.277172 460.024242 15.508687 482.262626 15.508687 509.672727s22.768485 49.648485 50.980202 49.648485h891.022222c28.198788 0 50.980202-22.238384 50.980202-49.648485-0.012929-27.410101-22.923636-49.648485-50.993131-49.648485z m0 351.63798H66.488889c-28.134141 0-50.980202 22.238384-50.980202 49.648485s22.833131 49.648485 50.980202 49.648485h891.022222c28.198788 0 50.980202-22.238384 50.980202-49.648485-0.012929-27.397172-22.781414-49.648485-50.993131-49.648485z m0 0"
                p-id="5134"
              ></path>
            </svg>
          )}
          {showMenu && (
            <svg
              t="1711673006565"
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="13280"
              width="24"
              height="24"
            >
              <path
                d="M512 466.944l233.472-233.472a31.744 31.744 0 0 1 45.056 45.056L557.056 512l233.472 233.472a31.744 31.744 0 0 1-45.056 45.056L512 557.056l-233.472 233.472a31.744 31.744 0 0 1-45.056-45.056L466.944 512 233.472 278.528a31.744 31.744 0 0 1 45.056-45.056z"
                fill="#5A5A68"
                p-id="13281"
              ></path>
            </svg>
          )}
        </div>

        <div
          className={clsx(
            " z-[-1]  max-sm:h-auto h-[64px] px-4 md:px-0 lg:px-0 xl:px-0 w-full md:w-auto lg:w-auto xl-w-auto sm:flex md:flex lg:flex xl:flex  xl:opacity-100 lg:opacity-100 md:opacity-100 items-center cursor-default hover:cursor-pointer gap-10  absolute 2xl:relative xl:relative lg:relative md:relative  md:left-0 xl:left-0 lg:left-0 top-[80px]  md:top-0 xl:top-0 lg:top-0 transition-all duration-500 md:bg-transparent lg:bg-transparent bg-gray-100",
            {
              "left-0 opacity-100 ": showMenu,
              "left-[-100%] opacity-0": !showMenu,
            }
          )}
        >
          {/* 导航链接 */}
          <a
            onClick={() => handleNavigation("/")}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/"),
              }
            )}
          >
            网站首页
          </a>
          <a
            onClick={(e) => handleNavigation("/service")}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full  relative",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/service"),
              }
            )}
          >
            使用场景
            {!showDropdown && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            )}
            {showDropdown && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            )}
            {
              <div
                ref={nodeRef}
                className={clsx(
                  "h-0 w-[140px] absolute top-3/4 left-0 bg-main-color text-white  px-4 mt-1 transition-all duration-500 z-50  overflow-hidden",
                  {
                    "py-2": showDropdown,
                  }
                )}
              >
                {/* 下拉菜单内容 */}
                <div className="text-sm">
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleNavigation("/service/digitalAuthentication");
                    }}
                    className="hover:text-hover-color leading-[30px] flex justify-between items-center"
                    onMouseEnter={() => setHovered("digitalAuthentication")}
                    onMouseLeave={() => setHovered("")}
                  >
                    数字认证
                    <svg
                      t="1711695763898"
                      className={`icon transition-all duration-300 ${
                        hovered === "digitalAuthentication"
                          ? "translate-x-0 opacity-100"
                          : "translate-x-[100%] opacity-0"
                      }`}
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14354"
                      width="12"
                      height="12"
                    >
                      <path
                        d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
                        fill="#ffffff"
                        p-id="14355"
                      ></path>
                    </svg>
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleNavigation("/service/signature");
                    }}
                    className="hover:text-hover-color leading-[30px] flex justify-between items-center"
                    onMouseEnter={() => setHovered("signature")}
                    onMouseLeave={() => setHovered("")}
                  >
                    电子签章
                    <svg
                      t="1711695763898"
                      className={`icon transition-all duration-300 ${
                        hovered === "signature"
                          ? "translate-x-0 opacity-100"
                          : "translate-x-[100%] opacity-0"
                      }`}
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14354"
                      width="12"
                      height="12"
                    >
                      <path
                        d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
                        fill="#ffffff"
                        p-id="14355"
                      ></path>
                    </svg>
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleNavigation("/service/approval");
                    }}
                    className="hover:text-hover-color leading-[30px] flex justify-between items-center"
                    onMouseEnter={() => setHovered("approval")}
                    onMouseLeave={() => setHovered("")}
                  >
                    审批管理
                    <svg
                      t="1711695763898"
                      className={`icon transition-all duration-300 ${
                        hovered === "approval"
                          ? "translate-x-0 opacity-100"
                          : "translate-x-[100%] opacity-0"
                      }`}
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14354"
                      width="12"
                      height="12"
                    >
                      <path
                        d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
                        fill="#ffffff"
                        p-id="14355"
                      ></path>
                    </svg>
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleNavigation("/service/contractManager");
                    }}
                    className="hover:text-hover-color leading-[30px] flex justify-between items-center"
                    onMouseEnter={() => setHovered("contractManager")}
                    onMouseLeave={() => setHovered("")}
                  >
                    合同管理
                    <svg
                      t="1711695763898"
                      className={`icon transition-all duration-300 ${
                        hovered === "contractManager"
                          ? "translate-x-0 opacity-100"
                          : "translate-x-[100%] opacity-0"
                      }`}
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14354"
                      width="12"
                      height="12"
                    >
                      <path
                        d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
                        fill="#ffffff"
                        p-id="14355"
                      ></path>
                    </svg>
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleNavigation("/service/signet");
                    }}
                    className="hover:text-hover-color leading-[30px] flex justify-between items-center"
                    onMouseEnter={() => setHovered("signet")}
                    onMouseLeave={() => setHovered("")}
                  >
                    印章管理
                    <svg
                      t="1711695763898"
                      className={`icon transition-all duration-300 ${
                        hovered === "signet"
                          ? "translate-x-0 opacity-100"
                          : "translate-x-[100%] opacity-0"
                      }`}
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14354"
                      width="12"
                      height="12"
                    >
                      <path
                        d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
                        fill="#ffffff"
                        p-id="14355"
                      ></path>
                    </svg>
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleNavigation("/service/dataDepositCertificate");
                    }}
                    className="hover:text-hover-color leading-[30px] flex justify-between items-center"
                    onMouseEnter={() => setHovered("dataDepositCertificate")}
                    onMouseLeave={() => setHovered("")}
                  >
                    数据存证
                    <svg
                      t="1711695763898"
                      className={`icon transition-all duration-300 ${
                        hovered === "dataDepositCertificate"
                          ? "translate-x-0 opacity-100"
                          : "translate-x-[100%] opacity-0"
                      }`}
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="14354"
                      width="12"
                      height="12"
                    >
                      <path
                        d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
                        fill="#ffffff"
                        p-id="14355"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
            }
          </a>
          <a
            onClick={() => handleNavigation("/yqy_down")}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/yqy_down"),
              }
            )}
          >
            应用下载
          </a>
          <a
            onClick={() => handleNavigation("/open")}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/open"),
              }
            )}
          >
            开放平台
          </a>
          <a
            onClick={() => handleNavigation("/video-tutorial")}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/video-tutorial"),
              }
            )}
          >
            视频教程
          </a>
          <a
            onClick={() => handleNavigation("/contact-us")}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/contact-us"),
              }
            )}
          >
            联系我们
          </a>
          <a
            onClick={() => handleNavigation("/join")}
            className={clsx(
              "mx-2  hover:text-hover-color flex justify-start md:justify-center lg:justify-center xl:justify-center items-center h-full",
              {
                " border-b-2 border-main-color text-hover-color w-fit":
                  isMatched("/join"),
              }
            )}
          >
            点击加盟
          </a>
        </div>
        <div
          className="absolute top-0 bottom-0 right-[60px] m-auto ml-8 max-sm:hidden flex items-center space-x-2 hover:text-main-color hover:cursor-pointer"
          onClick={() => window.open("http://pc.cloudqianyue.com", "_blank")}
        >
          <svg
            t="1712555452714"
            className="transition-all duration-300 transform cursor-pointer icon hover:scale-110"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7953"
            width="24"
            height="24"
          >
            <path
              d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m247.466667 298.666667l-238.933334 541.866666c-4.266667 4.266667-8.533333 8.533333-12.8 8.533334s-8.533333-4.266667-8.533333-8.533334l-38.4-256-256-34.133333c-4.266667 0-8.533333-4.266667-8.533333-8.533333 0-8.533333 4.266667-12.8 8.533333-12.8l546.133333-247.466667c4.266667 0 8.533333 0 12.8 4.266667 0 4.266667 0 8.533333-4.266666 12.8z"
              fill="#ea9518"
              p-id="7954"
            ></path>
          </svg>
          <span>Web端</span>
        </div>
      </div>
    </>
  );
}

export default Nav;
